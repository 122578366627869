.orderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  background-color: var(--secondary-bg-color);
  padding: 40px 40px;
  box-shadow: 0px 0px 30px rgb(132 132 132 / 25%);
  border-radius: 20px;
}

.order {
  text-align: center;
  margin-top: 20px;

  &__info {
    display: flex;
    align-items: center;
    margin-right: 50px;
  }
  &__products {
    width: 100%;
  }

  &__product {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 10px;
    h4 {
      font-weight: 400;
      font-size: 15px;
    }
  }

  &__img {
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }
}
