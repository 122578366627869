.orders {
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  margin-bottom: 150px;

  &__list {
    padding-bottom: 50px;
  }
}
