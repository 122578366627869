.add-card {
  color: var(--text-color);
  font-family: 'Montserrat';
  margin: 0 auto;
  max-width: 500px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }

  &__field-container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
  }

  &__input {
    padding: 10px;
    outline: none;
  }

  &__select {
    padding: 10px;
  }

  &__button {
    display: block;
    margin: 0 auto;
    padding: 10px;
    cursor: pointer;
  }

  &__success {
    color: var(--primary-color);
    text-align: center;
  }
  &__failed {
    color: #9f1313;
    text-align: center;
  }
}
