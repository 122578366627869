.header {
  padding: 60px 0;
  background-color: var(--bg-color);
  display: flex;
  justify-content: center;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: var(--bg-color);
    padding: 10px 0;
  }

  &__details {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  &__detail {
    background-color: var(--secondary-bg-color);
    padding: 10px 14px;
    border-radius: 100px;
    flex-shrink: 0;
    color: var(--text-color);
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;

    &--button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 45px;
      height: 45px;
      border-radius: 100%;
      cursor: pointer;
      border: none;

      &:hover {
        background-color: var(--primary-color);
      }
    }

    &--add-btn {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 576px) {
    .header {
      &__inner {
        padding: 10px 0;
        justify-content: center;
        row-gap: 30px;
      }
      &__logo {
        display: none;
      }
    }
  }
}
