.main {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  padding-left: 60px;

  background: no-repeat 100% url("/assets/images/loginImg.png");
  background-size: cover;
}

.error {
  color: red;
}

.auth {
  display: flex;
  width: 360px;
  min-width: 200px;
  flex-direction: column;

  img {
    margin-bottom: 100px;
  }

  &__title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 40px;
  }

  &__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;

    input {
      outline: none;
      margin-bottom: 10px;
      padding: 15px;
      gap: 10px;
      width: 100%;

      background: var(--secondary-bg-color);
      box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.24),
        -1px -3px 7px rgba(48, 213, 246, 0.35);
      border-radius: 100px;
      border: none;
    }
  }

  &__button {
    padding: 0px 24px;
    margin: 15px 0;
    gap: 14px;

    width: 80%;
    height: 40px;

    background: var(--primary-color);

    box-shadow: 0px 0px 15px rgba(48, 213, 246, 0.76);
    color: white;
    border-radius: 100px;
    border: none;

    &:hover{
      background-color: #38abdf;
    }
    &:active{
      background: var(--primary-color);
    }
  }
}

@media screen and (max-width: 576px) {
  .main {
    padding: 20px;
  }
}
