.cart {
  font-family: "Montserrat";

  &__items {
    overflow-y: auto;
    max-height: 350px;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 121.99%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--text-color);
  }

  &__empty-text {
    margin-top: 50px;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--text-color);
    text-align: center;

    &--success {
      color: var(--primary-color);
    }
  }

  &__close-icon {
    cursor: pointer;
  }

  &-card {
    padding: 24px 40px;
    color: var(--text-color);
    background-color: var(--card-color);
    margin-bottom: 12px;
    border-radius: 20px;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__image {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }

    &__title {
      font-weight: 600;
      font-size: 15px;
      line-height: 138.86%;
      letter-spacing: 0.04em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;

      @media screen and (max-width: 760px) {
        max-width: 100px;
      }

      @media screen and (max-width: 576px) {
        max-width: 60px;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
    }

    &__button,
    &__amount {
      font-weight: 400;
      font-size: 20px;
      line-height: 130.49%;
      letter-spacing: 0.04em;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.06),
        -1px -3px 3px rgba(48, 213, 246, 0.32);
      background-color: var(--button-bg-color);
      color: white;
    }

    &__amount {
      width: 44px;
      text-align: center;
    }

    &__button {
      display: flex;
      align-items: center;
      cursor: pointer;
      border: none;
      height: 35px;
      margin: 5px;
    }

    &__price {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.04em;
    }

    &__detail {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--secondary-bg-color);
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: none;
      cursor: pointer;

      &--added {
        background-color: var(--primary-color);
      }

      &:hover:not(.products-card__detail--added) {
        background-color: var(--bg-color);
      }
    }
  }

  &-conclusion {
    background-color: var(--card-color);
    padding: 24px 70px;
    border-radius: 20px;
    margin-top: 20px;

    &__inner {
      display: flex;
      justify-content: space-between;
    }

    &__input {
      width: 100%;
      max-width: 200px;
      font-size: 13px;
      padding: 6px 0 4px 10px;
      border: 1px solid #cecece;
      background: #f6f6f6;
      border-radius: 8px;
    }
    &__input:focus {
      color: #212529;
      background-color: #fff;
      border-color: #858cb2;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.25);
    }

    &__price-container {
      display: flex;
      align-items: center;
      color: var(--text-color);
      column-gap: 48px;
    }

    @media screen and (max-width: 576px) {
      &__inner {
        flex-direction: column;
        row-gap: 20px;
      }
      &__price-container {
        justify-content: space-between;
      }
    }

    &__text {
      font-weight: 600;
      font-size: 18px;
      line-height: 121.99%;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: var(--text-color);
    }
    &__number {
      font-weight: 600;
      font-size: 14px;
      line-height: 121.99%;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: var(--text-color);
      margin-right: 20px;
    }

    &__price {
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      letter-spacing: 0.04em;
      color: var(--text-color);

      &--attention {
        color: #9f1313;
      }
    }

    &__button {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      padding: 20px 45px;
      background-color: var(--primary-color);
      color: var(--text-color);
      cursor: pointer;
      border: none;
      border-radius: 38px;
      &--disabled {
        cursor: default;
        background-color: gray;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .cart-card {
    padding: 8px;
  }

  .cart-card__detail {
    width: 25px;
    height: 25px;
  }

  h3.cart-card__title {
    font-size: 12px;
  }

  img.cart-card__image {
    margin-right: 5px;
    height: 40px;
    width: 40px;
  }

  span.cart-card__price {
    font-size: 12px;
  }

  .cart-conclusion {
    padding: 15px 20px;
  }

  span.cart-conclusion__price {
    font-size: 25px;
  }

  .cart-card__amount,
  .cart-card__button {
    font-size: 12px;
    padding: 5px;
  }

  .cart-card__amount {
    width: 25px;
  }

  .cart-card__button {
    width: 17px;
    height: 25px;
  }
}
