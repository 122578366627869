.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
  }
  &__content {
    width: 984px;
    padding: 50px;
    border-radius: 20px;
    position: relative;
    background-color: var(--bg-color);

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
  &__close-icon {
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
  }
}
