.footer {
  background-color: var(--footer-color);
  padding: 80px 0;
  font-family: "Nunito";
  font-style: normal;
  color: #fff;


  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo,
  .logo {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    p {
      margin-left: 10px;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
  }
  &__contact {
    margin-bottom: 10px;
    display: flex;

    p {
      margin-left: 5px;
    }
  }
}

@media screen and (max-width: 576px) {
  .footer {
    padding: 40px 0;
    &__inner {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 30px;
    }
  }
}
