.toast-container {
  background-color: var(--secondary-bg-color);
}

.progress--success {
  background-color: var(--primary-color);
}

.progress--failed {
  background-color: #9f1313;
}
