@import 'reset', 'variables';

html,
body {
  min-height: 100vh;
}

body {
  background-color: var(--bg-color);
}

.container {
  max-width: 1150px;
  padding: 0 20px;
  margin: 0 auto;
}

.app {
  // font-family: 'Rubik Glitch';
}

.coin-l {
  width: 25px;
  height: 25px;
}

.coin-m {
  width: 20px;
  height: 20px;
}

.coin-s {
  width: 15px;
  height: 15px;
}
