:root {
  --bg-color: #241845;
  --secondary-bg-color: #393385;
  --primary-color: #30D5F6;
  --card-color: #36326B;;
  --footer-color: #181236;
  --text-color: #ffffff;
  --button-bg-color: #36326B;
  --button-text-color: #28303f;
  
}

// $bg-color: #28303f;
// $secondary-bg-color: #3f4a60;
// $primary-color: #36c3ff;
// $card-color: #3c475c;
// $footer-color: #212835;
// $text-color: #ffffff;
// $button-bg-color: #ffffff;
// $button-text-color: #28303f;
