.products-card {
  position: relative;
  max-width: 350px;
  border-radius: 20px;
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  padding: 50px 24px 30px;
  transition: all 0.3s;

  &:hover{
    box-shadow: 0px 0px 35px rgba(48, 213, 246, 0.53);
  }

  &__options {
    position: relative;

    &-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -30px;
      right: -15px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--secondary-bg-color);
      cursor: pointer;
      border: none;
      outline: none;

      &:hover {
        background-color: var(--bg-color);
      }

      &-icon {
        width: 15px;
        height: 15px;
        fill: var(--text-color);
      }
    }

    &-list {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 8px;
      background-color: var(--secondary-bg-color);
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
      min-width: 150px;
      display: none;
      user-select: none;

      &--open {
        display: block;
      }
    }

    &-item {
      border-radius: 8px;
      padding: 10px 20px;
      font-weight: 600;
      font-size: 15px;
      line-height: 138.86%;
      letter-spacing: 0.04em;
      cursor: pointer;

      &:hover {
        background-color: var(--bg-color);
      }
    }
  }

  &__image {
    width: 300px;
    min-width: 300px;
    height: 300px;
    object-fit: contain;
    margin-bottom: 50px;
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    color: var(--text-color);
    margin-bottom: 20px;
  }

  &__title {
    font-weight: 600;
    font-size: 15px;
    line-height: 138.86%;
    letter-spacing: 0.04em;
  }

  &__price {
    font-weight: 500;
    font-size: 15px;
    line-height: 130.99%;
    letter-spacing: 0.04em;
    flex-shrink: 0;
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__detail {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    padding: 10px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    color: #fff;

    &--added {
      background-color: var(--bg-color);
      cursor: default;
    }

    &:hover:not(.products-card__detail--added) {
      background-color: #38abdf;
    }
  }
}
